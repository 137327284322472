<template>
  <div class="message" :class="{'user-message': isUser, 'chatbot-message': !isUser}">
    <img :src="messageLogo" class="message-logo" />
    <div v-if="isUser" class="bubble">{{ message.content }}</div>
    <div v-else class="bubble">
      <span class="typewriter" v-html="message.content"></span>
      <span class="cursor" v-if="typing"></span>
      <div v-if="message.docs && message.docs.length > 0">
          <button v-if="message.docs && message.docs.length > 0" @click="toggleVacations" class="toggle-button">
            {{ showVacations ? 'Verberg' : `Toon documenten (top ${message.docs.length})` }}
          </button>
          <div v-if="showVacations && message.docs && message.docs.length > 0" class="vacations">
            <ul>
              <li v-for="vacation in message.docs" :key="vacation"><span class="typewriter" v-html="vacation"></span></li>
            </ul>
          </div>
      </div>
      <div v-if="!isUser && message.follow_up" class="interaction-buttons">
        <button @click="nextQuestion" class="interaction-button">Vervolgvraag</button>
        <button @click="changeAnswer" class="interaction-button">Nieuwe vraag</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      typedContent: '',
      typing: false,
      showVacations: false,
      timeoutId: null // Store the timeout ID
    };
  },
  computed: {
    messageLogo() {
      return this.isUser ? require('@/assets/user.png') : require('@/assets/chatbot.png');
    }
  },
  mounted() {
    //if (!this.isUser) {
    //  this.typeMessage();
    //}
  },
  beforeUnmount() { // Updated from beforeDestroy to beforeUnmount for Vue 3
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clear the timeout when component is unmounted
    }
  },
  methods: {
    typeMessage() {
      const content = this.message.content;
      let i = 0;
      const typeWriter = () => {
        if (i < content.length) {
          this.typedContent += content.charAt(i);
          i++;
          const speed = Math.floor(Math.random() * (25 - 4 + 1)) + 5;
          this.timeoutId = setTimeout(typeWriter, speed); // Update the timeout ID
        } else {
          this.typing = false;
        }
      };
      typeWriter();
    },
    toggleVacations() {
        this.showVacations = !this.showVacations;
    },
    nextQuestion() {
      this.$emit('nextQuestion');
    },
    changeAnswer() {
      this.$emit('changeAnswer');
    }
  }
};
</script>


<style scoped>
.message {
  display: flex;
  flex-direction: column; /* Make the main container a column */
  align-items: flex-start; /* Align items to the start by default */
  margin-bottom: 10px;
  margin-right: 20px;
  margin-left: 20px;
  font-family: Verdana, sans-serif;
  font-weight: 500;
}

.user-message {
  align-items: flex-end; /* Align user messages to the end (right side) */
}

.user-message .bubble {
  background-color: #0057f9;
  color: white;
  padding: 15px 15px;
  border-radius: 15px;
  word-break: break-word;
}

.chatbot-message .bubble {
  background-color: #dde4eb;
  color: #6f7276;
  padding: 15px 15px;
  border-radius: 15px;
  word-break: break-word;
}

.message-logo {
  width: 40px; /* Smaller width */
  height: 37.5px; /* Smaller height */
  border-radius: 50%;
  margin-bottom: 10px; /* Space between logo and text */
}

/* Optional: adjust logo margin for better spacing */
.user-message .message-logo {
  align-self: flex-end; /* Align the user logo to the right */
}

.chatbot-message .message-logo {
  align-self: flex-start; /* Align the chatbot logo to the left */
}

/* Style adjustments for positioning and margin/padding */
.user-message .message-logo {
  margin-right: 10px; /* Space between logo and message bubble for user */
}

.chatbot-message .message-logo {
  margin-left: 10px; /* Space between logo and message bubble for chatbot */
}

.typewriter {
  display: inline;
  white-space: pre-wrap; /* Allow wrapping and preserve whitespace */
  word-wrap: break-word; /* Break the word */
  overflow: hidden;
}

.cursor {
  display: inline-block;
  vertical-align: bottom;
  width: 2px;
  height: 1em;
  background-color: white;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to { background-color: transparent }
  50% { background-color: white }
}

.toggle-button {
  background-color: #273D5B;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  height: 50px;
  margin-top: 20px;
}

.toggle-button:hover {
  background-color: #0955a7;
}

.interaction-button {
  background-color: #0057f9;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 5px;
  border-radius: 4px;
  height: 50px;
  margin-top: 20px;
}

.interaction-button:hover {
  background-color: #0955a7;
}

</style>